import { memo, useMemo, useState } from 'react'
import styled from 'styled-components'
import { mediaQuery, getRelative, getP22, getP20, getP18, getP18_1, getTransition, getP25_2 } from '@/styles'
import { motion, AnimatePresence } from 'framer-motion'
import { useDeviceType, useMount } from '@/hooks'

const Wrapper = styled.div<{backGround?:string, isActive:boolean}>`
  align-content: space-between;
  background: ${props => `url(${props.backGround}) no-repeat top center`};
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);
  display: grid;
  height: ${getRelative(500, 'mobile')};
  margin: 0 auto;
  overflow:hidden;
  position: relative;
  width:100%;

  ${mediaQuery.greaterThan('tablet')`
    height: ${getRelative(750, 'desktop')};
    box-shadow: unset;
  `}

  .intro {
    display: grid;
    grid-template-columns: ${getRelative(220, 'mobile')} auto;
    height: min-content;
    padding: ${getRelative(15, 'mobile')} ${getRelative(10, 'mobile')};
    position: relative;
    row-gap: ${getRelative(5, 'mobile')};
    z-index: 2;

    ${mediaQuery.greaterThan('tablet')`
      grid-template-columns: repeat(4, 1fr);
      padding: ${getRelative(50, 'desktop')} ${getRelative(30, 'desktop')};
      row-gap: unset;
      grid-column: unset;
      grid-row:unset;
    `}

    .title {
      font-family: ${({ theme }) => theme.fonts.roboto_bold};
      font-weight: 500;
      grid-column: 1/span 1;
      grid-row:1;

      ${mediaQuery.greaterThan('tablet')`
        grid-column: unset;
        grid-row:unset;
    	`}

      .logo_rac100 {
        height: 70px;
        width: auto;
        transform: translateY(-12px);

        ${mediaQuery.greaterThan('tablet')`
          height: 90px;
          transform: translateY(-32px);

        `}
      }
    }

    .subtitle {
      font-family: ${({ theme }) => theme.fonts.roboto};
      grid-column:1/span 1;
      grid-row:2;

      ${mediaQuery.greaterThan('tablet')`
        grid-column: unset;
        grid-row:unset;
	    `}
    }

    .self_end {
      justify-self: self-end;
      padding-right: ${getRelative(10, 'mobile')};
    }

    h2,h3,h4 {
      text-align:left;
    }

    h2 {
      ${getP22()}
      font-weight:bold;
    }

    h4 {
      ${getP18()}
      grid-column:1/span 1;
      grid-row:3;

      ${mediaQuery.greaterThan('tablet')`
        grid-column: unset;
        grid-row:unset;
    	`}
    }

    h3 {
      ${getP20()}
      font-weight:normal;
    }
  }

  .close {
    grid-column: 3/span 4;
    grid-row:1;
    height: ${getRelative(20, 'mobile')};
    justify-self: flex-end;
    width: ${getRelative(20, 'mobile')};

		${mediaQuery.greaterThan('tablet')`
			grid-column: unset;
			grid-row:unset;
			height: ${getRelative(30, 'desktop')};
			width: ${getRelative(30, 'desktop')};
		`}
  }

  .logo {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    grid-column: 1/span 1;
    grid-row: 4;
    justify-self: flex-start;

		 ${mediaQuery.greaterThan('tablet')`
      grid-column: unset;
      grid-row: unset;
      justify-self: flex-end;
    `}

    img:not(.rac1_white, .rac1_black, .tve_black, .tve_white, .tv3_black, .tv3_white){
      display:block;
      height: auto;
      padding-bottom: ${getRelative(5, 'mobile')};
      width: ${getRelative(80, 'mobile')};

			${mediaQuery.greaterThan('tablet')`
				height: auto;
				padding-bottom: ${getRelative(10, 'desktop')};
        width: ${getRelative(130, 'desktop')};
			`}
    }
  }

  .overlay {
    background-color: #d0cfcfdd;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    .intro {
      color: ${({theme }) => theme.colors.black};
    }

    .content {
      align-items:end;
      display:grid;
      grid-template-columns: 1;
      grid-template-rows: 2;
      height: unset;
      padding: ${getRelative(40, 'mobile')} ${getRelative(10, 'mobile')};
      row-gap: ${getRelative(15, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        grid-template-columns: repeat(4,1fr);
        grid-template-rows: unset;
        height:48%;
        padding:  0 ${getRelative(35, 'desktop')} ${getRelative(35, 'desktop')} ${getRelative(35, 'desktop')};
        row-gap: unset;
      `}

      ${mediaQuery.greaterThan('desktop-xs')`
        height:78%;
      `}

      .about {
        ${getP18_1()}
        border-top: .5px solid ${({theme }) => theme.colors.black};
        border-right: unset;
        grid-column: 1 / span 4;
        grid-row:2;
        height: unset;
        padding: ${getRelative(20, 'mobile')} 0 0 0;

        ${mediaQuery.greaterThan('tablet')`
					border-top: unset;
					border-left: 1px solid ${({theme }) => theme.colors.black};
					grid-column: 2/span 4;
					grid-row:unset;
					height: 90%;
					padding: 0 ${getRelative(35, 'desktop')};
				`}

        span:nth-child(2):not(.actions_overlay span){
          display:block;
          padding-top: ${getRelative(10, 'mobile')};

          ${mediaQuery.greaterThan('tablet')`
            padding-top: ${getRelative(35, 'desktop')};
          `}
        }
      }

      .actions_overlay {
        padding-top: ${getRelative(20, 'mobile')};

        ${mediaQuery.greaterThan('tablet')`
          padding-top: ${getRelative(35, 'desktop')};
        `}

        a {
          display: flex;

          button {
            align-items:center;
            display: flex;
          }

          span {
            ${getP25_2()}
            font-family: ${({ theme }) => theme.fonts.roboto_bold};
            color: ${({ theme }) => theme.colors.black};
            display: inline;
            padding-left: ${getRelative(10, 'mobile')};

            ${mediaQuery.greaterThan('tablet')`
              padding-left: ${getRelative(15, 'desktop')};
          	`}
          }
        }

        img {
          height:  ${getRelative(25, 'mobile')};
          width:  ${getRelative(25, 'mobile')};

          ${mediaQuery.greaterThan('tablet')`
            height:  ${getRelative(25, 'desktop')};
            width:  ${getRelative(25, 'desktop')};
          `}
        }
      }
    }
  }

  .flex {
    display: grid;
    margin-bottom: ${getRelative(10, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      margin-bottom: ${getRelative(50, 'desktop')};
    `}
  }

  .logos {
    align-items: flex-end;
    flex-direction:column;
    grid-row: 1/span 3;
    padding-top:0;
    padding-right:unset;

    ${mediaQuery.greaterThan('tablet')`
      grid-row:unset;
      padding-right: ${getRelative(35, 'desktop')};
      padding-top: unset;
    `}

    img:not(.rac1_white, .rac1_black, .tve_black, .tve_white, .tv3_black, .tv3_white) {
      display:block;
      height:auto;
      padding-bottom: ${getRelative(15, 'mobile')};
      width: ${getRelative(80, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
				padding-bottom: ${getRelative(20, 'desktop')};
				width: ${getRelative(130, 'desktop')};
    	`}
    }
  }

  .desktop {
    display: none;
    margin-right: unset;

    ${mediaQuery.greaterThan('tablet')`
     display: inherit;
     margin-right: ${getRelative(20, 'desktop')};
    `}
  }

  .mobile {
    display: flex;

    ${mediaQuery.greaterThan('tablet')`
     display: none;
    `}
  }

  .actions {
    align-items: center;
    display: flex;
    justify-content: center;

    button {
      margin: ${getRelative(10, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        margin: 0 ${getRelative(30, 'desktop')};
      `}

      img {
        height: ${getRelative(100, 'mobile')};
        width: ${getRelative(100, 'mobile')};
        ${mediaQuery.greaterThan('tablet')`
          height: ${getRelative(220, 'desktop')};
          width: ${getRelative(220, 'desktop')};
        `}
      }
    }
  }

  .rac1_white, .rac1_black, .tv3_black, .tv3_white {
    height: auto;
    padding-right: ${getRelative(5, 'mobile')};
    width: ${getRelative(50, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      width: ${getRelative(80, 'desktop')};
      padding-right: 0;
    `}
  }

  .tve_black,
  .tve_white {
    display: block;
    height:auto;
    padding-bottom: unset;
    width: ${getRelative(80, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      padding-bottom: unset;
      width: ${getRelative(130, 'desktop')};
    `}
  }
`

const SectionStyled = styled(motion.section)<{backGround?:string, $order:any}>`
   height: 100%;
   order: ${({ $order }) => $order ?? 'auto'};
   margin: ${getRelative(10, 'mobile')} 0;
   width: 95%;

  ${mediaQuery.greaterThan('tablet')`
    margin: ${getRelative(60, 'desktop')} 0;
    width: 83%;
  `}

  &#projectesGALA {
    > div .overlay .content .about {
      height: 97% !important;
    }
  }
`

interface IProject {
  data: any
}

const overlayBackgroundVariants = {
  enter: {
    y: '100%',
    transition: { ...getTransition(.5), delay: .2 }
  },
  animate: {
    y: '0%',
    transition: { ...getTransition(.5),
      delay: .2 }
  },
  exit: {
    y: '100%',
    transition: { ...getTransition(.5) }
  }

}

const containerVariants = {
  enter: {
    opacity: 0,
    transition: { ...getTransition(.5), delay: .2 }
  },
  animate: {
    opacity: 1,
    transition: { ...getTransition(.5),
      delay: .2 }
  },
  exit: {
    opacity: 0,
    transition: { ...getTransition(.1), delay: 1.6 }
  }

}
const actionVariants = {
  enter: {
    opacity: 0,
    transition: { ...getTransition()}
  },
  animate: {
    opacity: 1,
    transition: { ...getTransition() }
  },
  exit: {
    opacity: 0,
    transition: { ...getTransition()}
  }

}
const textVariants = {
  enter: {
    y: '110%',
    transition: { ...getTransition(.5) }
  },
  animate: {
    y: '25%',
    transition: { ...getTransition(.3)}
  },
  exit: {
    y: '100%',
    transition: { ...getTransition(1.5) }
  }
}

const textVariantsMobile = {
  enter: {
    y: '110%',
    transition: { ...getTransition(.5) }
  },
  animate: {
    y: '30%',
    transition: { ...getTransition(.3)}
  },
  exit: {
    y: '100%',
    transition: { ...getTransition(1.5) }
  }
}
const buttonVariants = {
  enter: {
    opacity: 0,
    transition: { ...getTransition(.5)}
  },
  animate: {
    opacity: 1,
    transition: { ...getTransition(.1),
      delay: .6 }
  },
  exit: {
    opacity: 0,
    transition: { ...getTransition() }
  }
}

const colorVariants = {
  enter: {
    color: '#FFF',
    transition: { ...getTransition(.5)}
  },
  animate: {
    color: '#000000',
    transition: { ...getTransition(.1),
      delay: .5 }
  },
  exit: {
    color: '#FFF',
    transition: { ...getTransition() }
  }
}

const getLogos = (logos) => logos.map((logo,idx)=> {
  const string = logo.split('/')
  const alt= string[string.length -1].split('.')[0]

  return <img key={idx} className={alt} src={logo} alt={alt}/>
})

export const Project = memo<IProject>(({ data }) => {
  const { order, about_1, about_2 ,background_image, intro, link, logos, logos_white, subtitle, title, title2, amb} = data
  const Logos = useMemo(()=> getLogos(logos), [logos])
  const LogosWhite = useMemo(()=> getLogos(logos_white), [logos_white])
  const [isActive, setActive] = useState(false)
  const deviceType = useDeviceType()
  const isMount = useMount()

  const handleOnClick = () => {
    setActive(!isActive)
  }

  return (
    <SectionStyled $order={order} id={`projectes${title}`} layoutScroll>
      <Wrapper backGround={background_image} isActive={isActive}>
        <AnimatePresence mode='wait'>
          <motion.div className='intro' animate={isActive? 'animate': 'exit'} exit='exit' initial='enter' variants={colorVariants} >
            <div className='title'>
              {title === 'GALA' ?
                <AnimatePresence mode='wait'>
                  {isActive ? <motion.img className='logo_rac100'animate={isActive? 'animate': 'exit'} exit='exit' initial='enter' variants={buttonVariants} src='/images/img/logo_100_RAC_black.png' alt='rac1'></motion.img>
                    :
                    <motion.img className='logo_rac100' animate={!isActive ? 'animate': 'exit'} exit='exit' initial='enter' variants={containerVariants}  src='/images/img/logo_100_RAC_white.png' alt='rac1'>
                    </motion.img>
                  }
                </AnimatePresence>
                :
                <>
                  {deviceType === 'mobile' && isMount ?
                    <h2>{title} {title2}</h2>
                    :
                    <>
                      <h2>{title}</h2>
                      <h2>{title2}</h2>
                    </>
                  }
                </>

              }
             
            </div>
            <div className='subtitle'>
              {deviceType === 'mobile' && isMount ?
                <h3>{amb} {subtitle}</h3>
                :
                <>
                  <h3>{amb}</h3>
                  <h3>{subtitle}</h3>
                </>
              }
            </div>
            <h4>{intro}</h4>
            {isActive && <div className='mobile self_end'>
              <motion.button className='close' onClick={handleOnClick} animate={isActive? 'animate': 'exit'} exit='exit' initial='enter' variants={buttonVariants}><img src='/images/svg/i--cross.svg' alt='close_button'></img></motion.button>
            </div>}
            <div className='desktop'>
              <AnimatePresence mode='wait'>
                {isActive ? <motion.button className='close' onClick={handleOnClick} animate={isActive? 'animate': 'exit'} exit='exit' initial='enter' variants={buttonVariants}><img src='/images/svg/i--cross.svg' alt='close_button'></img></motion.button>
                  :
                  <motion.div className='logo' animate={!isActive ? 'animate': 'exit'} exit='exit' initial='enter' variants={containerVariants}>
                    {LogosWhite}
                  </motion.div>
                }
              </AnimatePresence>
            </div>
            {!isActive &&
              <div className='logos mobile'>
                {LogosWhite}
              </div>
            }
          </motion.div>
        </AnimatePresence>
        <AnimatePresence mode='wait'>
          {isActive && <motion.div className={'overlay'} animate={isActive? 'animate': 'exit'} exit='exit' initial='enter' variants={overlayBackgroundVariants}>
            <motion.div className='content'  animate={isActive? 'animate': 'exit'} exit='exit' initial='enter' variants={deviceType !== 'mobile' ? textVariants : textVariantsMobile}>
              <div className='logos desktop'>
                {Logos}
              </div>
              <div className='about'>
                <span>{about_1}</span>
                <span>{about_2}</span>
                <div className='actions_overlay'>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <button>
                      <img src='/images/svg/i--play.svg' alt='play_button'></img>
                      <span>Play</span>
                    </button>
                  </a>
                </div>
              </div>
            </motion.div>
          </motion.div>}
        </AnimatePresence>
        <AnimatePresence mode='wait'>
          {!isActive&& <motion.div className='flex' animate={!isActive ? 'animate': 'exit'} exit='exit' initial='enter' variants={containerVariants}>
            <motion.div className='actions' animate={!isActive ? 'animate': 'exit'} exit='exit' initial='enter' variants={actionVariants} >
              <button className='more' onClick={handleOnClick}> <img src='/images/svg/i--plus.svg' alt='more_button'></img> </button>
              <button className='info'> <a href={link} target="_blank" rel="noopener noreferrer"><img src='/images/svg/i--play--white.svg' alt='info_button'></img></a></button>
            </motion.div>
          </motion.div>}
        </AnimatePresence>
      </Wrapper>
    </SectionStyled>
  )
})
