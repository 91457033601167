import { useState, useEffect } from 'react'

export const useMount = () => {
  const [isMount, setMount] = useState(false)

  useEffect(() => {
    setMount(true)

    return () => setMount(false)
  }, [])

  return isMount
}