import { useEffect, useState } from 'react'

type TypeDeviceType = 'mobile' | 'tablet' | 'desktop'
interface iBreakpoints {
  mobile: number, tablet: number, desktopXs: number, desktop: number,
  getDeviceType: (width: number) => TypeDeviceType
}

const BREAKPOINTS: iBreakpoints = {
  mobile: 375,
  tablet: 768,
  desktopXs: 992,
  desktop: 1440,
  getDeviceType(width) {
    return width < this.tablet ? 'mobile' :
      width < this.desktopXs ? 'tablet' :
        'desktop'
  }
}

const getInfo = (isClient: boolean) => {
  return isClient ? {
    width: window.innerWidth,
    height: window.innerHeight,
    deviceType: BREAKPOINTS.getDeviceType(window.innerWidth)
  } : { width: undefined, height: undefined, deviceType: 'desktop' as const }
}

function debounce(func: Function, miliseconds = 600) {
  let timer: number
  return function (event: any) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(func, miliseconds, event)
  }
}

export const useDeviceType = () => {
  const isClient = typeof window !== 'undefined'
  const [deviceType, setDeviceType] = useState(getInfo(isClient).deviceType)

  const handleResize = () => {
    const info = getInfo(isClient)
    if (info.deviceType !== deviceType) setDeviceType(info.deviceType)
  }
  const debouncedHandler = debounce(handleResize)

  useEffect(() => {
    if (!isClient) return () => { }

    window.addEventListener('resize', debouncedHandler, { passive: true })
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', debouncedHandler)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return deviceType
}
