/* eslint-disable react-hooks/exhaustive-deps */
import Flickity from 'react-flickity-component'
import { useState, useEffect, memo } from 'react'

interface ISlider {
  children: any,
  loop?: boolean,
  autoPlay?: number,
  cellAlign?: string,
  className?: string,
  buttons?: boolean,
  pageDots?: boolean,
  freeScroll?: boolean,
  onLoad?: any,
  changePosition?: number | null,
  onStaticClick?: any,
  onChange?: any,
  arrowShape?: any,
  groupCells?: null | boolean | number | string,
}

let firstLoad = true
export const SliderFlickity = memo<ISlider>(({ children, loop = true, cellAlign = 'center', className = '', buttons = false, pageDots = true, freeScroll = true, changePosition = null, onChange, groupCells = false, autoPlay, arrowShape = null })  => {

  const [flikifly, setFliki] = useState(null)

  const options = {
    autoPlay,
    cellAlign,
    contain: !loop,
    freeScroll,
    groupCells,
    imagesLoaded: false,
    initialIndex: 0,
    lazyLoad: 1,
    pageDots,
    draggable: false,
    rightToLeft: true,
    pauseAutoPlayOnHover: false,
    prevNextButtons: buttons,
    resize: true,
    wrapAround: loop,
    ...(arrowShape && { arrowShape })
  }

  // useEffect(() => {
  //   if(flikifly) {
  //     flikifly.resize()

  //     setTimeout(() => {
  //       onLoad && onLoad()
  //       setTimeout(() => flikifly.resize(), 1000)
  //     }, 1500)
  //   }
  // }, [flikifly, children, onLoad])

  useEffect(() => {
    if(typeof window === 'object' && flikifly && firstLoad) {
      firstLoad = false
      const timer = window.setTimeout(() => {
        flikifly.next()
      }, 13580)

      return () => {
        window.clearTimeout(timer)
      }
    }
  }, [flikifly])


  useEffect(() => {
    if (!changePosition || !flikifly) return

    flikifly.selectCell(changePosition, false, true)
  }, [changePosition])

  useEffect(() => {
    function onStaticClick () {
      flikifly.playPlayer()
    }
    if(flikifly) {
      flikifly.on('staticClick', onStaticClick)
      return () => { flikifly.off( 'staticClick', onStaticClick ) }
    }
  }, [flikifly])


  useEffect(() => {
    if(onChange && flikifly)  {
      flikifly.on( 'change', onChange.bind(this, flikifly))
      return () => { flikifly.off( 'change', onChange ) }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flikifly])

  // DragMove & DragEnd avoid missclicks on links while dragging
  // CSS styles in the reset.ts
  useEffect(() => {
    function dragMove() {
      document.ontouchmove = () => false
      document.body.classList.add('slider-being-dragged')
    }
    if(flikifly)  {
      flikifly.on( 'dragMove', dragMove)
      return () => flikifly.off(dragMove)
    }
  }, [flikifly])

  useEffect(() => {
    function dragStart() {
      document.ontouchmove = () => false
    }

    if(flikifly)  {

      flikifly.on( 'dragStart', dragStart)
      return () => flikifly.off(dragStart)
    }
  }, [flikifly])

  useEffect(() => {
    function dragEnd() {
      document.ontouchmove = () => true
      setTimeout(() => {
        document.body.classList.remove('slider-being-dragged')
      }, 50)
    }
    if(flikifly)  {
      flikifly.on( 'dragEnd', dragEnd)
      return () => flikifly.off(dragEnd)
    }
  }, [flikifly])

  return (
    //@ts-ignore
    <Flickity reloadOnUpdate flickityRef={(ref) => setFliki(ref)} className={'slider--flickity ' + className} elementType={'div'} options={options}>
      {children}
    </Flickity>
  )
})



