export const home = (data) => {
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    'name': 'Monmedia',
    'url': 'https://monmedia.tv',
    'isPartOf': {
      '@type': 'WebSite'
    },
    'publisher': {
      '@type': 'Organization',
      ...(data.seo && { 'name': `${data.seo.title}` }),
      ...(data.seo && { 'description': `${data.seo.description}` }),
      'brand': 'Monmedia',
      'email': 'hola@monmedia.tv',
      'url': 'https://monmedia.tv',
      'sameAs': [
        'https://www.instagram.com/monmedia.tv/?igshid=YmMyMTA2M2Y%3D',
      ],
      'image': 'https://monmedia.tv/images/og_cover.jpg',  // required CHECK
      'contactPoint': [
        {
          '@type': 'ContactPoint',
          'email': 'hola@monmedia.tv',
          'contactType': 'Servei de contacte',
          'availableLanguage': ['Catalan']
        }
      ],
      'logo': 'https://monmedia.tv/images/logo--monmedia.png'
    },
    'mainEntity': {
      '@type': ['ItemList'],
      'itemListOrder': 'https://schema.org/ItemListOrderAscending',
      'numberOfItems': data.projects.length,
      'itemListElement': data.projects.map((project, idx) => ({
        'author': 'Monmedia.TV',
        '@type': ['ListItem', 'CreativeWork'],
        'position': idx,
        'headline': `${project.title} ${project.title2}`,
        'alternativeHeadline': project.intro,
        'description': project.about_1,
        'about': project.about_2,
        'isFamilyFriendly': true,
        'thumbnailUrl': project.background_image
      }))
    }
  })
}
